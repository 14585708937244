var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._t("default"),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("h2", [_vm._v("Logo")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "The Alabama Maternal and Child Health (MCH) Leadership Network is a group of Maternal and Child Health Bureau funded Leadership Training Programs in Alabama."
        )
      ]),
      _vm._v(" "),
      _c("h2", [_vm._v("Intended Audience")]),
      _vm._v(" "),
      _c("p", [
        _vm._v("Intended audience includes MCH Trainees and the MCH community.")
      ]),
      _vm._v(" "),
      _c("h2", [_vm._v("Design and Creation")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "It was requested that the colors from both University of Alabama at Birmingham and Alabama State University be included in the logo. Illustrator was used to create this logo."
        )
      ])
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "picright" }, [
      _c("div", { staticClass: "logo" }, [
        _c("div", {
          staticClass: "pic-center-sm",
          attrs: {
            id: "logo-amchln",
            title: "Alabama Maternal and Child Health Leadership Network"
          }
        })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }