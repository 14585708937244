var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._t("default"),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("h2", [_vm._v("Logo")]),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c("h2", [_vm._v("Intended Audience")]),
      _vm._v(" "),
      _vm._m(2),
      _vm._v(" "),
      _c("h2", [_vm._v("Design and Creation")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "The logo design is based on look and feel of a diploma. Illustrator was used to create this logo."
        )
      ])
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "picright" }, [
      _c("div", { staticClass: "logo" }, [
        _c("div", {
          staticClass: "pic-center-sm",
          attrs: {
            id: "logo-credentials",
            title: "Credentials - A Necessity for the 21st Century"
          }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("em", [_vm._v("Credentials")]),
      _vm._v(" provides tips for getting college credentials.")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("em", [_vm._v("Credentials")]),
      _vm._v(" is intended for high school students.")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }