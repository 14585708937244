var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._t("default"),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("h2", [_vm._v("3rd Page Vertical Ad")]),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c("h2", [_vm._v("Intended Audience")]),
      _vm._v(" "),
      _vm._m(2),
      _vm._v(" "),
      _c("h2", [_vm._v("Design and Creation")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "I wanted to create something eye-catching and simple. I thought the balloons being attached to a money bag would grab their attention. In addition, the black background stood out from other ads on same page. Photoshop and InDesign were used to create this ad."
        )
      ])
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "picright" }, [
      _c("div", { staticClass: "webss" }, [
        _c("div", {
          staticClass: "print_wtpps05 no-border pic-center-sm",
          attrs: {
            title: "Win The Perfect Prom Sweepstakes - 3rd Page Vertical Ad"
          }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "This 3rd Page Vertical Ad was placed in the Dec. 2004/Jan. 2005 issue of "
      ),
      _c("em", [_vm._v("Cosmo Girl")]),
      _vm._v(
        ". High school students had a chance to win money for their school's prom, personal prom expenses, and a live prom concert."
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("em", [_vm._v("Win The Perfect Prom Sweepstakes")]),
      _vm._v(" is intended for high school students.")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }