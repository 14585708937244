var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "picrightcircle" },
        [
          _c("router-link", { attrs: { to: { name: "Web" } } }, [
            _c("div", { staticClass: "home-circle", attrs: { title: "web" } })
          ])
        ],
        1
      ),
      _vm._v(" "),
      _vm._t("default"),
      _vm._v(" "),
      _c("h2", [_vm._v("Summary")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Over 20 years of experience in developing & designing web and print media. Organized, reliable team member with strong attention to detail. Hard worker who is eager to learn new skills."
        )
      ]),
      _vm._v(" "),
      _c("h2", [_vm._v("About This Site")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Vue.js, Vuex, Sass (SCSS), Node.js, Webpack, Grunt, Visual Studio Code, Illustrator, and Photoshop were used to create this responsive web site."
        )
      ]),
      _vm._v(" "),
      _c("h2", [_vm._v("Experience Highlights")]),
      _vm._v(" "),
      _c("ul", [
        _vm._m(0),
        _vm._v(" "),
        _vm._m(1),
        _vm._v(" "),
        _c("ul", [
          _c(
            "li",
            [
              _c(
                "router-link",
                { attrs: { to: { name: "ServeDashboardNotifications" } } },
                [_vm._v('View "Serve - Dashboard Notifications" feature')]
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm._m(2)
        ]),
        _vm._v(" "),
        _vm._m(3),
        _vm._v(" "),
        _vm._m(4),
        _vm._v(" "),
        _vm._m(5),
        _vm._v(" "),
        _c("ul", [
          _c(
            "li",
            [
              _c(
                "router-link",
                { attrs: { to: { name: "MCHStrategicPlanningWorkgroup" } } },
                [_vm._v('View "MCH Strategic Planning Workgroup"')]
              )
            ],
            1
          )
        ])
      ])
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [_c("strong", [_vm._v("Vue.js")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [_c("strong", [_vm._v("React")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("em", [_vm._v("React version of this portfolio site is in progress.")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [_c("strong", [_vm._v("AngularJS")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _vm._v(
          "AngularJS version of this portfolio website is available upon request."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [_c("strong", [_vm._v("WordPress")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }