import hoverintent from 'hoverintent/dist/hoverintent.min';

export default function (el) {
  const megaMenuSubmenu = el.getElementsByClassName('submenu');
  const megaMenuMainAnchor = el.querySelector('a');
  const megaMenuAllAnchors = el.querySelectorAll('a');

  // msMaxTouch Points is for IE10
  // else check all other browsers
  const testTouch = () => {
    if (window.navigator.msMaxTouchPoints !== undefined) {
      return (window.msMaxTouchPoints > 0);
    }
    // Return true if touchscreen - onmsgesturechange is for IE11+
    return !!('ontouchstart' in window) || !!('onmsgesturechange' in window);
  };

  if (!testTouch()) {
    megaMenuAllAnchors.forEach((mmAnchor) => {
      mmAnchor.addEventListener('click', () => {
        $(megaMenuSubmenu)
          .stop()
          .fadeTo('fast', 0)
          .hide();
      });
    });

    hoverintent(el,
      () => {
        // Handler in
        if (window.innerWidth > 661) {
          megaMenuMainAnchor.setAttribute(
            'style',
            'border-top:4px solid #be1e2d; padding-top:5px'
          );
          $(megaMenuSubmenu)
            .stop()
            .fadeTo('fast', 1)
            .show();
        }
      },
      () => {
        // Handler out
        if (window.innerWidth > 661) {
          megaMenuMainAnchor.setAttribute(
            'style',
            'border-top:0px solid #be1e2d; padding-top:9px'
          );
          $(megaMenuSubmenu)
            .stop()
            .fadeTo('fast', 0)
            .hide();
        }
      }).options({
      timeout: 500,
      sensitivity: 1,
      interval: 50
    });
  }
}
