import Vue from 'vue';
import Router from 'vue-router';

import Home from '../Components/Views/Home/index.vue';

import Web from '../Components/Views/Web/index.vue';
import AlabamaMCHLeadershipNetwork from '../Components/Views/Web/AlabamaMCHLeadershipNetwork.vue';
import HealthCareTransitionPlanningGuide from '../Components/Views/Web/HealthCareTransitionPlanningGuide.vue';
import HealthCareTransitions from '../Components/Views/Web/HealthCareTransitions.vue';
import MCHStrategicPlanningWorkgroup from '../Components/Views/Web/MCHStrategicPlanningWorkgroup.vue';
import ServeDashboardNotifications from '../Components/Views/Web/ServeDashboardNotifications.vue';
import ServeDashboardRedesign from '../Components/Views/Web/ServeDashboardRedesign.vue';
import ServeMailACheck from '../Components/Views/Web/ServeMailACheck.vue';
import ServeOneTimePassword from '../Components/Views/Web/ServeOneTimePassword.vue';

import Logos from '../Components/Views/Logos/index.vue';
import AlabamaMCHLeadershipNetworkLogo from '../Components/Views/Logos/AlabamaMCHLeadershipNetwork.vue';
import BrightBeginnings from '../Components/Views/Logos/BrightBeginnings.vue';
import Credentials from '../Components/Views/Logos/Credentials.vue';
import EngineeredForSuccess from '../Components/Views/Logos/EngineeredForSuccess.vue';
import MCHBerkeley from '../Components/Views/Logos/MCHBerkeley.vue';
import MCHResearchProgram from '../Components/Views/Logos/MCHResearchProgram.vue';
import MCHTrainingProgram from '../Components/Views/Logos/MCHTrainingProgram.vue';

import Print from '../Components/Views/Print/index.vue';
import GetFitStayFit from '../Components/Views/Print/GetFitStayFit.vue';
import HealthCareTransitionTraining from '../Components/Views/Print/HealthCareTransitionTraining.vue';
import JobSearch from '../Components/Views/Print/JobSearch.vue';
import LookingGood from '../Components/Views/Print/LookingGood.vue';
import PerfectProms from '../Components/Views/Print/PerfectProms.vue';
import WhenYouAre18 from '../Components/Views/Print/WhenYouAre18.vue';
import WinThePerfectPromSweepstakes from '../Components/Views/Print/WinThePerfectPromSweepstakes.vue';

import Contact from '../Components/Views/Contact/index.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
      meta: {
        // pageTitle will be used in head title, breadcrumb and h1 on page
        pageTitle: 'Home'
      }
    },
    {
      path: '/Web',
      name: 'Web',
      component: Web,
      meta: {
        pageTitle: 'Web'
      }
    },
    {
      path: '/Web/AlabamaMCHLeadershipNetwork',
      name: 'AlabamaMCHLeadershipNetwork',
      component: AlabamaMCHLeadershipNetwork,
      meta: {
        pageTitle: 'Alabama MCH Leadership Network'
      }
    },
    {
      path: '/Web/HealthCareTransitionPlanningGuide',
      name: 'HealthCareTransitionPlanningGuide',
      component: HealthCareTransitionPlanningGuide,
      meta: {
        pageTitle: 'Health Care Transition Planning Guide'
      }
    },
    {
      path: '/Web/HealthCareTransitions',
      name: 'HealthCareTransitions',
      component: HealthCareTransitions,
      meta: {
        pageTitle: 'Health Care Transitions'
      }
    },
    {
      path: '/Web/MCHStrategicPlanningWorkgroup',
      name: 'MCHStrategicPlanningWorkgroup',
      component: MCHStrategicPlanningWorkgroup,
      meta: {
        pageTitle: 'MCH Strategic Planning Workgroup'
      }
    },
    {
      path: '/Web/ServeDashboardNotifications',
      name: 'ServeDashboardNotifications',
      component: ServeDashboardNotifications,
      meta: {
        pageTitle: 'Serve - Dashboard Notifications'
      }
    },
    {
      path: '/Web/ServeDashboardRedesign',
      name: 'ServeDashboardRedesign',
      component: ServeDashboardRedesign,
      meta: {
        pageTitle: 'Serve - Dashboard Redesign'
      }
    },
    {
      path: '/Web/ServeMailACheck',
      name: 'ServeMailACheck',
      component: ServeMailACheck,
      meta: {
        pageTitle: 'Serve - Mail A Check'
      }
    },
    {
      path: '/Web/ServeOneTimePassword',
      name: 'ServeOneTimePassword',
      component: ServeOneTimePassword,
      meta: {
        pageTitle: 'Serve - One Time Password'
      }
    },
    {
      path: '/Logos',
      name: 'Logos',
      component: Logos,
      meta: {
        pageTitle: 'Logos'
      }
    },
    {
      path: '/Logos/AlabamaMCHLeadershipNetwork',
      name: 'AlabamaMCHLeadershipNetworkLogo',
      component: AlabamaMCHLeadershipNetworkLogo,
      meta: {
        pageTitle: 'Alabama MCH Leadership Network'
      }
    },
    {
      path: '/Logos/BrightBeginnings',
      name: 'BrightBeginnings',
      component: BrightBeginnings,
      meta: {
        pageTitle: 'Bright Beginnings'
      }
    },
    {
      path: '/Logos/Credentials',
      name: 'Credentials',
      component: Credentials,
      meta: {
        pageTitle: 'Credentials'
      }
    },
    {
      path: '/Logos/EngineeredForSuccess',
      name: 'EngineeredForSuccess',
      component: EngineeredForSuccess,
      meta: {
        pageTitle: 'Engineered For Success'
      }
    },
    {
      path: '/Logos/MCHBerkeley',
      name: 'MCHBerkeley',
      component: MCHBerkeley,
      meta: {
        pageTitle: 'MCH Program, University of California, Berkeley'
      }
    },
    {
      path: '/Logos/MCHResearchProgram',
      name: 'MCHResearchProgram',
      component: MCHResearchProgram,
      meta: {
        pageTitle: 'MCH Research Program'
      }
    },
    {
      path: '/Logos/MCHTrainingProgram',
      name: 'MCHTrainingProgram',
      component: MCHTrainingProgram,
      meta: {
        pageTitle: 'MCH Training Program'
      }
    },
    {
      path: '/Print',
      name: 'Print',
      component: Print,
      meta: {
        pageTitle: 'Print'
      }
    },
    {
      path: '/Print/GetFitStayFit',
      name: 'GetFitStayFit',
      component: GetFitStayFit,
      meta: {
        pageTitle: 'Get Fit! Stay Fit!'
      }
    },
    {
      path: '/Print/HealthCareTransitionTraining',
      name: 'HealthCareTransitionTraining',
      component: HealthCareTransitionTraining,
      meta: {
        pageTitle: 'Health Care Transition Training Program for Professionals'
      }
    },
    {
      path: '/Print/JobSearch',
      name: 'JobSearch',
      component: JobSearch,
      meta: {
        pageTitle: 'Job Search'
      }
    },
    {
      path: '/Print/LookingGood',
      name: 'LookingGood',
      component: LookingGood,
      meta: {
        pageTitle: 'Looking Good'
      }
    },
    {
      path: '/Print/PerfectProms',
      name: 'PerfectProms',
      component: PerfectProms,
      meta: {
        pageTitle: 'Perfect Proms'
      }
    },
    {
      path: '/Print/WhenYouAre18',
      name: 'WhenYouAre18',
      component: WhenYouAre18,
      meta: {
        pageTitle: "When You're 18"
      }
    },
    {
      path: '/Print/WinThePerfectPromSweepstakes',
      name: 'WinThePerfectPromSweepstakes',
      component: WinThePerfectPromSweepstakes,
      meta: {
        pageTitle: 'Win The Perfect Prom Sweepstakes'
      }
    },
    {
      path: '/Contact',
      name: 'Contact',
      component: Contact,
      meta: {
        pageTitle: 'Contact'
      }
    },
    {
      path: '/*',
      redirect: {
        name: 'Home'
      }
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  }
});
