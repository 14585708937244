var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._t("default"),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "The Alabama Maternal and Child Health (MCH) Leadership Network is a group of Maternal and Child Health Bureau funded Leadership Training Programs in Alabama."
        )
      ]),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c("p", [
        _vm._v("Please Note: Brochure was developed by another organization.")
      ]),
      _vm._v(" "),
      _c("h2", [_vm._v("Software and Languages")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Dreamweaver, Photoshop, Illustrator, JavaScript, CSS, and PHP were used to create this web site."
        )
      ]),
      _vm._v(" "),
      _vm._m(2)
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "picright" }, [
      _c("div", { staticClass: "webss" }, [
        _c(
          "a",
          { attrs: { href: "http://al.mchtraining.net", target: "_blank" } },
          [
            _c("div", {
              staticClass: "web_amchln pic-center-sm",
              attrs: {
                title:
                  "Screenshot of the Alabama MCH Leadership Network Web Site"
              }
            })
          ]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("Design and development of web site. "),
      _c("a", { attrs: { "ui-sref": "logos.AlabamaMCHLeadershipNetwork" } }, [
        _vm._v("Design of Alabama MCH Leadership Network logo.")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("strong", [
        _c(
          "a",
          { attrs: { href: "http://al.mchtraining.net", target: "_blank" } },
          [_vm._v("Visit the Alabama MCH Leadership Network web site.")]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }