import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

// Using Constants for Mutation Types
// https://vuex.vuejs.org/guide/mutations.html#using-constants-for-mutation-types
const ISBREADCRUMBDISPLAYED_GET = 'ISBREADCRUMBDISPLAYED_GET';
const ISBREADCRUMBDISPLAYED_UPDATE = 'ISBREADCRUMBDISPLAYED_UPDATE';
const ISMOBILENAVMENUDISPLAYED_GET = 'ISMOBILENAVMENUDISPLAYED_GET';
const ISMOBILENAVMENUDISPLAYED_UPDATE = 'ISMOBILENAVMENUDISPLAYED_UPDATE';
const PAGETITLE_GET = 'PAGETITLE_GET';
const PAGETITLE_UPDATE = 'PAGETITLE_UPDATE';
const PARENTPAGENAME_GET = 'PARENTPAGENAME_GET';
const PARENTPAGENAME_UPDATE = 'PARENTPAGENAME_UPDATE';


export default new Vuex.Store({
  // state object is where all data is stored
  state: {
    isBreadcrumbDisplayed: false,
    isMobileNavMenuDisplayed: false,
    pageTitle: '',
    parentPageName: 'Home'
  },
  // all changes to store happen through mutations
  // do not access state directory
  mutations: {
    // we can use the ES2015 computed property name feature
    // to use a constant as the function name
    [ISBREADCRUMBDISPLAYED_UPDATE](state, isDisplayed) {
      state.isBreadcrumbDisplayed = isDisplayed;
    },
    [ISMOBILENAVMENUDISPLAYED_UPDATE](state) {
      state.isMobileNavMenuDisplayed = !state.isMobileNavMenuDisplayed;
    },
    [PAGETITLE_UPDATE](state, newTitle) {
      state.pageTitle = newTitle;
    },
    [PARENTPAGENAME_UPDATE](state, newParentPageName) {
      state.parentPageName = newParentPageName;
    }
  },
  getters: {
    [ISBREADCRUMBDISPLAYED_GET](state) {
      return state.isBreadcrumbDisplayed;
    },
    [ISMOBILENAVMENUDISPLAYED_GET](state) {
      return state.isMobileNavMenuDisplayed;
    },
    [PAGETITLE_GET](state) {
      return state.pageTitle;
    },
    [PARENTPAGENAME_GET](state) {
      return state.parentPageName;
    }
  }
});
