var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._t("default"),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Web site of the Health Care Transition Initiative at the Institute for Child Health Policy, University of Florida."
        )
      ]),
      _vm._v(" "),
      _c("p", [_vm._v("Design and development of web site.")]),
      _vm._v(" "),
      _c("h2", [_vm._v("Software and Languages")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Dreamweaver, Photoshop, Illustrator, JavaScript, CSS, and PHP were used to create this web site."
        )
      ]),
      _vm._v(" "),
      _vm._m(1)
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "picright" }, [
      _c("div", { staticClass: "webss" }, [
        _c("div", {
          staticClass: "web_hct pic-center-sm",
          attrs: { title: "Screenshot of the Health Care Transitions Web Site" }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("strong", [_vm._v("Please note")]),
      _vm._v(
        ":  The Health Care Transition web site was recently decommissioned due to lack of grant funds."
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }