<script>
import NavMobile from './NavMobile.vue';
import Header from './Header.vue';
import Nav from './Nav.vue';
import Footer from './Footer.vue';

export default {
    components: {
        'layout-nav-mobile': NavMobile,
        'layout-header': Header,
        'layout-nav': Nav,
        'layout-footer': Footer
    },
    methods: {
        isBreadcrumbDisplayedGetHandler() {
            return this.$store.getters.ISBREADCRUMBDISPLAYED_GET;
        },
        pageTitleGetHandler() {
            return this.$store.getters.PAGETITLE_GET;
        },
        parentPageNameGetHandler() {
            return this.$store.getters.PARENTPAGENAME_GET;
        }
    }
}
</script>

<template>
    <div>
        <!-- BEGIN Mobile Navigation -->
        <layout-nav-mobile>
        </layout-nav-mobile>
        <!-- END Mobile Navigation -->

        <!-- BEGIN Header -->
        <layout-header>
        </layout-header>   
        <!-- END Header -->

        <!-- BEGIN Navigation -->
        <layout-nav>
        </layout-nav>  
        <!-- END Navigation -->
        
        <!-- BEGIN Content -->
        <div id="contentbckgrnd">
            <div id="container">
                <article role="main">
                    <div id="content">
                        <div id="maincontent">
                            <a name="mainbodyanchr" id="mainbodyanchr"></a>
                            <transition-group name="page-transition">
                                <p class="breadcrumb" :key='1' v-show="isBreadcrumbDisplayedGetHandler()">
                                    <span class="sro">&lt;-</span> <router-link :to="{name: parentPageNameGetHandler()}">{{parentPageNameGetHandler()}}</router-link>
                                </p>
                                
                                <router-view :key='2'>
                                    <h1>{{pageTitleGetHandler()}}</h1>
                                </router-view>
                            </transition-group>
                                        
                            <div class="clear"></div>
                            <!-- skiplinks id is for screen readers and keyboard users (test by pressing tab in browser) -->
                            <div class="skiplinks">
                                <p class="nomargin"><a href="#navigation">Skip To Navigation</a> <a href="#top">Back To Top</a> <a href="#mainbodyanchr">Skip To Main Content</a></p>
                            </div>
                        </div>
                    </div>
                </article>
            </div>
        </div>
        <!-- END Content -->
        
        <!-- BEGIN Footer -->
        <layout-footer>
        </layout-footer>  
        <!-- END Footer -->
    </div>
</template>