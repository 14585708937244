var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._t("default"),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("h2", [_vm._v("Poster")]),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c("h2", [_vm._v("Intended Audience")]),
      _vm._v(" "),
      _vm._m(2),
      _vm._v(" "),
      _c("h2", [_vm._v("Design and Creation")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "I wanted to create a fun and active poster. In an attempt to appeal to more high school males I used blue. Photoshop, Illustrator, and InDesign were used to create this poster."
        )
      ])
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "picright" }, [
      _c("div", { staticClass: "webss" }, [
        _c("div", {
          staticClass: "print_pp03 pic-center-sm",
          attrs: { title: "Perfect Proms - Poster" }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("The "),
      _c("em", [_vm._v("Perfect Proms")]),
      _vm._v(
        " poster includes area for high school prom coordinators to write in time, date and location of their school's prom. Sponsors have the opportunity to advertise on poster."
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("em", [_vm._v("Perfect Proms")]),
      _vm._v(" is intended for high school students.")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }