<script>
import NavMobileHamburger from './NavMobileHamburger.vue';
import MobileNavMenuHandlerMixin from '../../Mixins/mobileNavMenuHandler-Mixin.js';

export default {
    name: 'Header',
    components: { NavMobileHamburger },
    mixins: [MobileNavMenuHandlerMixin]
}
</script>

<template>
  <div id="headerbckgrnd">
      <div id="container">
          <!-- skiplinks id is for screen readers and keyboard users (test by pressing tab in browser) -->
          <div class="skiplinks">
              <p class="nomargin"><a href="#mainbodyanchr">Skip To Main Content</a> <a href="#navigation">Skip To Navigation</a></p>
          </div>
          <p class="printpageheader">Robert Peck <span class="sro">:|:</span> Web Developer &amp; Designer</p>
          <header role="banner">
              <div id="header">
                  <div id="logo-wrapper">
                      <NavMobileHamburger :onClick="isMobileNavMenuDisplayedUpdateHandler" />
                      <router-link :to="{name: 'Home'}">
                          <div id="logo"></div>
                      </router-link>
                  </div>
              </div>
          </header>
      </div>
  </div>
</template>
