var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._t("default"),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("h2", [_vm._v("Booklet")]),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c("h2", [_vm._v("Intended Audience")]),
      _vm._v(" "),
      _vm._m(2),
      _vm._v(" "),
      _c("h2", [_vm._v("Design and Creation")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "I thought it would a fun idea to merge the task of searching for a job and a buried treasure map. I created a treasure map for finding jobs as the design for the booklet. Photoshop, Illustrator, and InDesign were used to create this booklet."
        )
      ])
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "picright" }, [
      _c("div", { staticClass: "print" }, [
        _c("div", {
          staticClass: "print_js00 pic-center-sm",
          attrs: { title: "Job Search - Digest-Size Booklet" }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("em", [_vm._v("Job Search")]),
      _vm._v(
        " includes helpful tips for finding a job. Sponsors have the opportunity to advertise in booklet."
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("em", [_vm._v("Job Search")]),
      _vm._v(" is intended for college students.")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }