var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._t("default"),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("h2", [_vm._v("Posters, Guide and Survey")]),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c("h2", [_vm._v("Intended Audience")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "The posters are intended for middle school males and females. Male poster is for male gym locker room and female poster is for female gym locker room. The guide is intended for high school males and females. The survey letter is for the teachers to provide feedback."
        )
      ]),
      _vm._v(" "),
      _c("h2", [_vm._v("Design and Creation")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "With summer tips being the theme I thought teens exercising outdoors would be appropriate. I made the posters horizontal so the sponsor's product shot would be larger. Photoshop, Illustrator, and InDesign were used to create these materials."
        )
      ])
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "picright" }, [
      _c("div", { staticClass: "print" }, [
        _c("div", {
          staticClass: "print_gfsf06 pic-center-sm",
          attrs: { title: "Get Fit! Stay Fit! - Posters, Guide and Survey" }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("em", [_vm._v("Get Fit Stay Fit")]),
      _vm._v(
        " includes fitness and nutrition tips for middle school students. Sponsors have the opportunity to advertise in the guide and on both posters."
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }