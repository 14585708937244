var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isMobileNavMenuDisplayedGetHandler(),
            expression: "isMobileNavMenuDisplayedGetHandler()"
          }
        ],
        attrs: { id: "navmobilebckgrnd" }
      },
      [
        _c("div", { attrs: { id: "container" } }, [
          _c("header", { attrs: { role: "banner" } }, [
            _c("div", { attrs: { id: "header" } }, [
              _c(
                "div",
                { attrs: { id: "logo-wrapper" } },
                [
                  _c("NavMobileHamburger", {
                    attrs: {
                      onClick: _vm.isMobileNavMenuDisplayedUpdateHandler
                    }
                  }),
                  _vm._v(" "),
                  _c("div", { attrs: { id: "logo" } })
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c("nav", { attrs: { role: "navigation" } }, [
            _c("div", { attrs: { id: "nav" } }, [
              _c("ul", [
                _c(
                  "li",
                  [
                    _c(
                      "router-link",
                      {
                        attrs: { to: { name: "Home" } },
                        nativeOn: {
                          click: function($event) {
                            return _vm.isMobileNavMenuDisplayedUpdateHandler(
                              $event
                            )
                          }
                        }
                      },
                      [
                        _c("span", { staticClass: "sro" }, [_vm._v("::")]),
                        _vm._v("home\n                      ")
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  [
                    _c(
                      "router-link",
                      {
                        attrs: { to: { name: "Web" } },
                        nativeOn: {
                          click: function($event) {
                            return _vm.isMobileNavMenuDisplayedUpdateHandler(
                              $event
                            )
                          }
                        }
                      },
                      [
                        _c("span", { staticClass: "sro" }, [_vm._v("::")]),
                        _vm._v("web\n                      ")
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  [
                    _c(
                      "router-link",
                      {
                        attrs: { to: { name: "Logos" } },
                        nativeOn: {
                          click: function($event) {
                            return _vm.isMobileNavMenuDisplayedUpdateHandler(
                              $event
                            )
                          }
                        }
                      },
                      [
                        _c("span", { staticClass: "sro" }, [_vm._v("::")]),
                        _vm._v("logos\n                      ")
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  [
                    _c(
                      "router-link",
                      {
                        attrs: { to: { name: "Print" } },
                        nativeOn: {
                          click: function($event) {
                            return _vm.isMobileNavMenuDisplayedUpdateHandler(
                              $event
                            )
                          }
                        }
                      },
                      [
                        _c("span", { staticClass: "sro" }, [_vm._v("::")]),
                        _vm._v("print\n                      ")
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  [
                    _c(
                      "router-link",
                      {
                        attrs: { to: { name: "Contact" } },
                        nativeOn: {
                          click: function($event) {
                            return _vm.isMobileNavMenuDisplayedUpdateHandler(
                              $event
                            )
                          }
                        }
                      },
                      [
                        _c("span", { staticClass: "sro" }, [_vm._v("::")]),
                        _vm._v("contact\n                        ")
                      ]
                    )
                  ],
                  1
                )
              ])
            ])
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }