var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._t("default"),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "A collaboration with the Product Manager, Director of Presentation and Communication,Presentation Technical Architect, and ASP.NET Web Developers. The goal was to create a self-contained and compact module that can easily replace the submit button area on any page that may require a one time password. My primary focus was the initial design and development of the responsive user interface."
        )
      ]),
      _vm._v(" "),
      _c("h2", [_vm._v("Software and Languages")]),
      _vm._v(" "),
      _c("p", [
        _vm._v("jQuery and Sass (SCSS) were used to create this feature.")
      ]),
      _vm._v(" "),
      _vm._m(1)
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "picright" }, [
      _c("div", { staticClass: "webss" }, [
        _c("div", {
          staticClass: "web_serve_otp pic-center-sm",
          attrs: { title: "Screenshot of Serve One Time Password Feature" }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("strong", [
        _c(
          "a",
          { attrs: { href: "https://secure.serve.com", target: "_blank" } },
          [_vm._v("Visit the American Express Serve web site.")]
        )
      ]),
      _vm._v(
        " An American Express Serve account is required to login and view this feature."
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }