var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._t("default"),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "This self-paced planning guide is designed to assist parents and their children with developing a health care transition plan."
        )
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Design and development of MySQL Server database. Custom programming includes user accounts with encrypted login. Each account includes separate forms for a parent and a child to complete. System compares answers and makes suggestions on areas that require attention."
        )
      ]),
      _vm._v(" "),
      _c("h2", [_vm._v("Software and Languages")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Dreamweaver, Photoshop, Illustrator, JavaScript, CSS, MySQL, and PHP were used to create this web site."
        )
      ]),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _vm._m(2)
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "picright" }, [
      _c("div", { staticClass: "webss" }, [
        _c("div", {
          staticClass: "web_hctpg pic-center-sm",
          attrs: {
            title: "Screenshot of the Health Care Transition Planning Web Site"
          }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("strong", [_vm._v("Please note")]),
      _vm._v(
        ":  The Health Care Transition Planning Guide web site was recently decommissioned due to lack of grant funds. However, the below video was developed by a co-worker to give users a quick tutorial to the site. The video was produced sometime ago but it will provide an overview of the functionality."
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "video-embed-responsive" }, [
      _c("iframe", {
        attrs: {
          width: "560",
          height: "315",
          src:
            "https://www.youtube-nocookie.com/embed/lreqrOZIrlA?rel=0&showinfo=0",
          frameborder: "0",
          allowfullscreen: ""
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }