var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._t("default"),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("h2", [_vm._v("Poster, Guide and Handout")]),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c("h2", [_vm._v("Intended Audience")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Poster and Handout are intended for high school females. Guide is intended for high school males and females."
        )
      ]),
      _vm._v(" "),
      _c("h2", [_vm._v("Design and Creation")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "With summer being the theme I pictured teens enjoying their summer outdoors. Blue was used to represent a clear, blue sky. Bright, fun colors were used as accents. Photoshop, Illustrator, and InDesign were used to create these materials."
        )
      ])
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "picright" }, [
      _c("div", { staticClass: "print" }, [
        _c("div", {
          staticClass: "print_lg04 pic-center-sm",
          attrs: { title: "Looking Good - Poster, Guide and Handout" }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("em", [_vm._v("Looking Good")]),
      _vm._v(
        " includes summer health tips for high school students. Sponsors have the opportunity to advertise in the guide, handout and on the poster."
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }