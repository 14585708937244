var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("layout-nav-mobile"),
      _vm._v(" "),
      _c("layout-header"),
      _vm._v(" "),
      _c("layout-nav"),
      _vm._v(" "),
      _c("div", { attrs: { id: "contentbckgrnd" } }, [
        _c("div", { attrs: { id: "container" } }, [
          _c("article", { attrs: { role: "main" } }, [
            _c("div", { attrs: { id: "content" } }, [
              _c(
                "div",
                { attrs: { id: "maincontent" } },
                [
                  _c("a", {
                    attrs: { name: "mainbodyanchr", id: "mainbodyanchr" }
                  }),
                  _vm._v(" "),
                  _c(
                    "transition-group",
                    { attrs: { name: "page-transition" } },
                    [
                      _c(
                        "p",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isBreadcrumbDisplayedGetHandler(),
                              expression: "isBreadcrumbDisplayedGetHandler()"
                            }
                          ],
                          key: 1,
                          staticClass: "breadcrumb"
                        },
                        [
                          _c("span", { staticClass: "sro" }, [_vm._v("<-")]),
                          _vm._v(" "),
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: { name: _vm.parentPageNameGetHandler() }
                              }
                            },
                            [_vm._v(_vm._s(_vm.parentPageNameGetHandler()))]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("router-view", { key: 2 }, [
                        _c("h1", [_vm._v(_vm._s(_vm.pageTitleGetHandler()))])
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "clear" }),
                  _vm._v(" "),
                  _vm._m(0)
                ],
                1
              )
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("layout-footer")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "skiplinks" }, [
      _c("p", { staticClass: "nomargin" }, [
        _c("a", { attrs: { href: "#navigation" } }, [
          _vm._v("Skip To Navigation")
        ]),
        _vm._v(" "),
        _c("a", { attrs: { href: "#top" } }, [_vm._v("Back To Top")]),
        _vm._v(" "),
        _c("a", { attrs: { href: "#mainbodyanchr" } }, [
          _vm._v("Skip To Main Content")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }