var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "footerbckgrnd" } }, [
      _c("div", { attrs: { id: "container" } }, [
        _c("footer", { attrs: { role: "contentinfo" } }, [
          _c("div", { attrs: { id: "footer" } }, [
            _c("div", { attrs: { id: "copyright" } }, [
              _c("p", { staticClass: "nomargin" }, [
                _vm._v("\n            © 2024 robert peck  "),
                _c("span", { staticClass: "sro" }, [
                  _vm._v("\n              :|:\n            ")
                ]),
                _vm._v("  "),
                _c(
                  "a",
                  { attrs: { href: "mailto:robert@robert-d-peck.com" } },
                  [
                    _vm._v(
                      "\n              robert@robert-d-peck.com\n            "
                    )
                  ]
                )
              ])
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }