var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._t("default"),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("h2", [_vm._v("Logo")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Designed for the Maternal & Child Health (MCH) Program at the School of Public Health, University of California, Berkeley."
        )
      ]),
      _vm._v(" "),
      _c("h2", [_vm._v("Intended Audience")]),
      _vm._v(" "),
      _c("p", [
        _vm._v("Intended audience includes MCH Trainees and the MCH community.")
      ]),
      _vm._v(" "),
      _c("h2", [_vm._v("Design and Creation")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "I wanted to create something fun, playful, and professional. Illustrator was used to create this logo."
        )
      ])
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "picright" }, [
      _c("div", { staticClass: "logo" }, [
        _c("div", {
          staticClass: "pic-center-sm",
          attrs: {
            id: "logo-mchpucb",
            title:
              "Maternal & Child Health Program, University of California, Berkeley"
          }
        })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }