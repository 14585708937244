export default {
  methods: {
    isMobileNavMenuDisplayedGetHandler() {
      return this.$store.getters.ISMOBILENAVMENUDISPLAYED_GET;
    },
    isMobileNavMenuDisplayedUpdateHandler() {
      this.$store.commit('ISMOBILENAVMENUDISPLAYED_UPDATE');
    }
  }
};
