var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "headerbckgrnd" } }, [
    _c("div", { attrs: { id: "container" } }, [
      _vm._m(0),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c("header", { attrs: { role: "banner" } }, [
        _c("div", { attrs: { id: "header" } }, [
          _c(
            "div",
            { attrs: { id: "logo-wrapper" } },
            [
              _c("NavMobileHamburger", {
                attrs: { onClick: _vm.isMobileNavMenuDisplayedUpdateHandler }
              }),
              _vm._v(" "),
              _c("router-link", { attrs: { to: { name: "Home" } } }, [
                _c("div", { attrs: { id: "logo" } })
              ])
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "skiplinks" }, [
      _c("p", { staticClass: "nomargin" }, [
        _c("a", { attrs: { href: "#mainbodyanchr" } }, [
          _vm._v("Skip To Main Content")
        ]),
        _vm._v(" "),
        _c("a", { attrs: { href: "#navigation" } }, [
          _vm._v("Skip To Navigation")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "printpageheader" }, [
      _vm._v("Robert Peck "),
      _c("span", { staticClass: "sro" }, [_vm._v(":|:")]),
      _vm._v(" Web Developer & Designer")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }