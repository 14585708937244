var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._t("default"),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("h2", [_vm._v("Half-Fold Brochure")]),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c("h2", [_vm._v("Intended Audience")]),
      _vm._v(" "),
      _c("p", [_vm._v("Brochure is intended for health care professionals.")]),
      _vm._v(" "),
      _c("h2", [_vm._v("Design and Creation")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "A two color brochure was one of the requirements. I placed focus on the large question mark to grab attention. Photoshop and InDesign were used to create this brochure."
        )
      ])
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "picright" }, [
      _c("div", { staticClass: "print" }, [
        _c("div", {
          staticClass: "print_hcttpp pic-center-sm",
          attrs: {
            title:
              "Health Care Transition Training Program for Professionals - Brochure"
          }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("This is a promotional brochure for the "),
      _c("em", [
        _vm._v("Health Care Transition Training Program for Professionals")
      ]),
      _vm._v(".")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }