<script>
export default {
    name: 'NavMobileHamburger',
    // Validate Props
    props: {
        onClick: {
            type: Function,
            required: true
        }
    }
}
</script>

<template>
  <div id="nav-mobile-hamburger-container" v-on:click="onClick">
      <div id="nav-mobile-hamburger">
          <div class="nav-mobile-hamburger-line"></div>
          <div class="nav-mobile-hamburger-line"></div>
          <div class="nav-mobile-hamburger-line"></div>
      </div>
  </div>
</template>