var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._t("default"),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "A collaboration with the Product Manager, UX Designer, and ASP.NET Web Developers. This project was to convert the Dashboard from a single column to a two column layout at the large breakpoint and reduce space in the blue Wallet area. This provided the opportunity to give multiple Dashboard modules exposure at the top of the page instead of them being buried at the bottom of the page."
        )
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "The two column layout is responsive and changes back to a single column at the small and medium breakpoints."
        )
      ]),
      _vm._v(" "),
      _c("h2", [_vm._v("Software and Languages")]),
      _vm._v(" "),
      _c("p", [_vm._v("Sass (SCSS) was used to redesign this feature.")]),
      _vm._v(" "),
      _vm._m(1)
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "picright" }, [
      _c("div", { staticClass: "webss" }, [
        _c("div", {
          staticClass: "web_serve_dashboardredesign pic-center-sm",
          attrs: { title: "Screenshot of Serve Dashboard" }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("strong", [
        _c(
          "a",
          { attrs: { href: "https://secure.serve.com", target: "_blank" } },
          [_vm._v("Visit the American Express Serve web site.")]
        )
      ]),
      _vm._v(
        " An American Express Serve account is required to login and view this feature."
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }