var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._t("default"),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "The MCH Training Strategic Plan web site, part of a WordPress Multisite network, is a custom WordPress theme which I designed. I have experience with installation and maintenance of WordPress sites including WordPress Multisite networks."
        )
      ]),
      _vm._v(" "),
      _c("h2", [_vm._v("Software and Languages")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "WordPress Multisite, Dreamweaver, Photoshop, Illustrator, JavaScript, CSS, MySQL, and PHP were used to create this web site."
        )
      ]),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _vm._m(2)
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "picright" }, [
      _c("div", { staticClass: "webss" }, [
        _c("p", { staticClass: "nomargin" }, [
          _c("img", {
            attrs: {
              src: "Content/Images/Misc/web_spw.jpg",
              alt: "Screenshot of the MCH Training Strategic Plan Web Site",
              width: "300",
              height: "181",
              border: "0"
            }
          })
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("strong", [_vm._v("Please note")]),
      _vm._v(
        ":  The MCH Training Strategic Plan web site was decommissioned due to lack of grant funds. However, the below video was developed by a co-worker to give Grantees a quick tutorial of editing pages in WordPress. The WordPress site shown in the video is MCH Nursing which was part of the Multisite network. This video were produced sometime ago but it will provide an overview of some of the functionality."
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "video-embed-responsive" }, [
      _c("iframe", {
        attrs: {
          width: "560",
          height: "315",
          src:
            "https://www.youtube-nocookie.com/embed/mMi2XjdiY4o?rel=0&showinfo=0",
          frameborder: "0",
          allowfullscreen: ""
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }