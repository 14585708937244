<script>
import megaMenuDirective from '../../Directives/megaMenu-Directive.js';

export default {
    name: 'Nav',
    directives: {
        megamenu: megaMenuDirective
    }
}
</script>

<template>
  <div id="navbckgrnd">
      <div id="container">
          <nav role="navigation">
              <a name="navigation" id="navigation"></a>
              <div id="nav">                
                  <ul>
                      <li><router-link :to="{name: 'Home'}"><span class="sro">::</span>home</router-link></li>
                      <li v-megamenu>
                          <router-link :to="{name: 'Web'}"><span class="sro">::</span>web</router-link>
                          <div class="submenu">
                              <div class="submenu-col">
                                  <div class="submenu-content">
                                      <ul>
                                        <li>
                                            <router-link :to="{name: 'ServeDashboardNotifications'}">Serve - Dashboard Notifications</router-link>
                                        </li>
                                        <li>
                                            <router-link :to="{name: 'ServeMailACheck'}">Serve - Mail A Check</router-link>
                                        </li>
                                        <li>
                                            <router-link :to="{name: 'ServeOneTimePassword'}">Serve - One Time Password</router-link>
                                        </li>
                                        <li>
                                            <router-link :to="{name: 'ServeDashboardRedesign'}">Serve - Dashboard Redesign</router-link>
                                        </li>
                                      </ul>
                                  </div>
                              </div>
                              <div class="submenu-col">
                                  <div class="submenu-content">
                                      <ul>
                                        <li>
                                            <router-link :to="{name: 'MCHStrategicPlanningWorkgroup'}">MCH Strategic Planning Workgroup</router-link>
                                        </li>
                                        <li>
                                            <router-link :to="{name: 'HealthCareTransitionPlanningGuide'}">Health Care Transition Planning Guide</router-link>
                                        </li>
                                        <li>
                                            <router-link :to="{name: 'HealthCareTransitions'}">Health Care Transitions</router-link>
                                        </li>
                                        <li>
                                            <router-link :to="{name: 'AlabamaMCHLeadershipNetwork'}">Alabama MCH Leadership Network</router-link>
                                        </li>
                                      </ul>
                                  </div>
                              </div>
                          </div>
                      </li>
                      <li v-megamenu>
                          <router-link :to="{name: 'Logos'}"><span class="sro">::</span>logos</router-link>
                          <div class="submenu">
                              <div class="submenu-col">
                                  <div class="submenu-content">
                                      <ul>
                                          <li>
                                            <router-link :to="{name: 'BrightBeginnings'}">Bright Beginnings</router-link>
                                          </li>
                                          <li>
                                            <router-link :to="{name: 'Credentials'}">Credentials</router-link>
                                          </li>
                                          <li>
                                            <router-link :to="{name: 'MCHBerkeley'}">MCH Program, University of California, Berkeley</router-link>
                                          </li>
                                          <li>
                                            <router-link :to="{name: 'AlabamaMCHLeadershipNetworkLogo'}">Alabama MCH Leadership Network</router-link>
                                          </li>
                                      </ul>
                                  </div>
                              </div>
                              <div class="submenu-col">
                                  <div class="submenu-content">
                                      <ul>
                                          <li>
                                            <router-link :to="{name: 'EngineeredForSuccess'}">Engineered For Success</router-link>
                                          </li>
                                          <li>
                                            <router-link :to="{name: 'MCHResearchProgram'}">MCH Research Program</router-link>
                                          </li>
                                          <li>
                                            <router-link :to="{name: 'MCHTrainingProgram'}">MCH Training Program</router-link>
                                          </li>
                                      </ul>
                                  </div>
                              </div>
                          </div>
                      </li>
                      <li v-megamenu>
                          <router-link :to="{name: 'Print'}"><span class="sro">::</span>print</router-link>
                          <div class="submenu">
                              <div class="submenu-col">
                                  <div class="submenu-content">
                                      <ul>
                                          <li>
                                            <router-link :to="{name: 'WhenYouAre18'}">When You're 18</router-link>
                                          </li>
                                          <li>
                                            <router-link :to="{name: 'LookingGood'}">Looking Good</router-link>
                                          </li>
                                          <li>
                                            <router-link :to="{name: 'HealthCareTransitionTraining'}">Health Care Transition Training Program for Professionals</router-link>
                                          </li>
                                          <li>
                                            <router-link :to="{name: 'GetFitStayFit'}">Get Fit! Stay Fit!</router-link>
                                          </li>
                                      </ul>
                                  </div>
                              </div>
                              <div class="submenu-col">
                                  <div class="submenu-content">
                                      <ul>
                                          <li>
                                            <router-link :to="{name: 'WinThePerfectPromSweepstakes'}">Win The Perfect Prom Sweepstakes</router-link>
                                          </li>
                                          <li>
                                            <router-link :to="{name: 'JobSearch'}">Job Search</router-link>
                                          </li>
                                          <li>
                                            <router-link :to="{name: 'PerfectProms'}">Perfect Proms</router-link>
                                          </li>
                                      </ul>
                                  </div>
                              </div>
                          </div>
                      </li>
                      <!-- <li><a href="Content/Docs/robert-peck_resume.pdf" target="_self"><span class="sro">::</span>resume</a></li> -->
                      <li>
                          <router-link :to="{name: 'Contact'}">
                              <span class="sro">::</span>contact
                          </router-link>
                      </li>
                  </ul>
              </div>
          </nav>
      </div>
  </div>
</template>
