import Vue from 'vue';
import Layout from './Components/Layout/index.vue';
import router from './Router';
import store from './Store';

// Use Navigation Guard to update:
// -- pageTitle in store
// -- update title in head
// -- breadcrumb
// This beforeEach should be placed above $mount or it wil not run on site load
router.beforeEach((to, from, next) => {
  const rpTitle = 'Robert Peck - Web Developer & Designer';
  const pagePath = to.path;
  const pathArray = pagePath.split('/');
  let newTitle = to.meta.pageTitle;
  let parentName = 'Home';
  let displayBreadcrumb = false;

  const sendGoogleAnalyticsPageview = () => {
    // track pageview on state change
    // eslint-disable-next-line no-undef
    gtag('config', 'UA-106096683-1', {
      // eslint-disable-next-line quote-props
      'page_title': newTitle,
      // eslint-disable-next-line quote-props
      'page_path': pagePath
    });
  };

  const updatePageAndHeadTitle = () => {
    // Check if undefined
    if (newTitle === undefined) {
      newTitle = '';
    }
    // UPDATE store with new title
    store.commit('PAGETITLE_UPDATE', newTitle);

    if (newTitle !== '') {
      newTitle += ' :|: ';
    }

    // Update <head> <title>
    document.title = newTitle + rpTitle;
  };

  const updateBreadcrumb = () => {
    // Update Breadcrumb
    if (pathArray.length >= 3) {
      // Get parent name from path
      const parentPosition = pathArray.length - 2;
      parentName = pathArray[parentPosition];
      // Display breadcrumb
      displayBreadcrumb = true;
    }
    // UPDATE store with parent name
    store.commit('PARENTPAGENAME_UPDATE', parentName);
    // UPDATE store if breadcrumb should be displayed
    store.commit('ISBREADCRUMBDISPLAYED_UPDATE', displayBreadcrumb);
  };

  sendGoogleAnalyticsPageview();
  updatePageAndHeadTitle();
  updateBreadcrumb();
  next(); // Move on to next hook
});

new Vue({
  router,
  store,
  render: h => h(Layout),
}).$mount('#app');
