var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._t("default"),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "A collaboration with the Product Manager, UX Designer, and ASP.NET Web Developers. My primary focus as the UI Developer was to create the initial responsive user interface. Check graphics were provided by UX Designer. Worked with other Web Developers to convert the Mail a Check feature into an AngularJS application."
        )
      ]),
      _vm._v(" "),
      _c("h2", [_vm._v("Software and Languages")]),
      _vm._v(" "),
      _c("p", [
        _vm._v("AngularJS and Sass (SCSS) were used to create this feature.")
      ]),
      _vm._v(" "),
      _vm._m(1)
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "picright" }, [
      _c("div", { staticClass: "webss" }, [
        _c("div", {
          staticClass: "web_serve_mailcheck pic-center-sm",
          attrs: { title: "Screenshot of Serve Mail a Check Feature" }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("strong", [
        _c(
          "a",
          { attrs: { href: "https://secure.serve.com", target: "_blank" } },
          [_vm._v("Visit the American Express Serve web site.")]
        )
      ]),
      _vm._v(
        " An American Express Serve account is required to login and view this feature."
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }