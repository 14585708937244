<script>
import NavMobileHamburger from './NavMobileHamburger.vue';
import MobileNavMenuHandlerMixin from '../../Mixins/mobileNavMenuHandler-Mixin.js';

export default {
    name: 'NavMobile',
    components: { NavMobileHamburger },
    mixins: [MobileNavMenuHandlerMixin]
}
</script>

<template>
  <transition>
  <div id="navmobilebckgrnd" v-show="isMobileNavMenuDisplayedGetHandler()">
      <div id="container">
          <header role="banner">
              <div id="header">
                  <div id="logo-wrapper">
                      <NavMobileHamburger :onClick="isMobileNavMenuDisplayedUpdateHandler" />
                      <div id="logo"></div>
                  </div>
              </div>
          </header>
          <nav role="navigation">
              <div id="nav">                
                  <ul>
                      <li>
                        <router-link 
                            :to="{name: 'Home'}" 
                            v-on:click.native="isMobileNavMenuDisplayedUpdateHandler"
                        >
                            <span class="sro">::</span>home
                        </router-link>
                      </li>
                      <li>
                        <router-link 
                            :to="{name: 'Web'}" 
                            v-on:click.native="isMobileNavMenuDisplayedUpdateHandler"
                        >
                            <span class="sro">::</span>web
                        </router-link>
                      </li>
                      <li>
                        <router-link 
                            :to="{name: 'Logos'}" 
                            v-on:click.native="isMobileNavMenuDisplayedUpdateHandler"
                        >
                            <span class="sro">::</span>logos
                        </router-link>
                      </li>
                      <li>
                        <router-link 
                            :to="{name: 'Print'}" 
                            v-on:click.native="isMobileNavMenuDisplayedUpdateHandler"
                        >
                            <span class="sro">::</span>print
                        </router-link>
                      </li>
                      <!-- <li>
                          <a href="Content/Docs/robert-peck_resume.pdf" target="_self"><span class="sro">::</span>resume</a>
                      </li> -->
                      <li>
                          <router-link 
                            :to="{name: 'Contact'}"
                            v-on:click.native="isMobileNavMenuDisplayedUpdateHandler"
                          >
                              <span class="sro">::</span>contact
                          </router-link>
                      </li>
                  </ul>
              </div>
          </nav>
      </div>
  </div>
  </transition>
</template>
